.w-25rem {
    width: 25rem;
}

.card-adjusted-image {
    width: 100%;
    min-height: 400px;
    max-height: 400px;
    object-fit: contain;
    height: auto;
}

.card-shadow {
    box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.22);
}