.edit-size {
    width: 95%;
    margin-left: 5%;
}

.btn-edit-size {
    min-width: 140px;
}

.edited-input-bar {
    max-width: 94%;
}