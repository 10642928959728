.accordion-button:not(.collapsed) {
  background-color: rgb(217, 217, 217);
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button::after {
  background-image: initial;
  width: 0;
}

.accordion-button:not(.collapsed)::after {
  background-image: initial;
  width: 0;
}

ul li:hover {
  background-color: rgb(232, 232, 233);
}

.category-icon-container {
  width: 2em;
}
