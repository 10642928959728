.ynet-logo-img {
  height: 3.5vh;
  vertical-align: text-bottom;
}

.profile-icon-img {
  height: 3.5vh;
  vertical-align: text-bottom;
  margin-right: 1vh;
}

.ml-1rem {
  margin-left: 1rem;
}
