.modal-header .btn-close {
    margin: initial !important;
    position: absolute;
}

.pb-12vh {
    padding-bottom: 12vh;
}

.m-0-auto {
    margin: 0 auto;
}

.edit-modal-close-button {
    border-radius: 40px;
    padding-right: 4vh;
    padding-left: 4vh;
}